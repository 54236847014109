// FFs
export const FEEDBACK_PAGE_FF = 'learning_app_fe_feedback_page';
export const EMAIL_SUBSCRIPTION_FF = 'learning_app_fe_email_subscription';
export const CHAMPIONS_PAGE_FF = 'community-champions-page';

export enum Environment {
  'DEVELOPMENT' = 'development',
  'STAGING' = 'staging',
  'PRODUCTION' = 'production',
}

//Hubs type
export enum ContentHubType {
  CERT_PREP = 'cert-prep',
  LEARNING_PATHS = 'all-learning-paths',
  ROLE_BASED = 'role-based',
  CERTIFICATIONS = 'certifications',
}

export enum CollectionType {
  TOPIC = 'topic',
  ROLE_BASED = 'role-based',
  CERT_PREP = 'cert-prep',
}

export enum LandingType {
  LIVE_TRAINING = 'live-training',
  CERTIFICATION_DETAIL = 'certification-detail',
}
export enum ResourceType {
  SmLesson = 'lesson',
  SmCourse = 'course',
  LearningPath = 'learningPath',
  Collection = 'collection',
  Certification = 'certification',
  InstructorLedTraining = 'instructorLedTraining',
}
