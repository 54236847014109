import React, { useMemo } from 'react';
import {
  CommunityApp,
  CommunityNavigation,
  NavBarExpandableItem,
  MenuItemWithAnalytics,
  LinkType,
} from '@atlassian/learning-components';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { css } from '@compiled/react';
import { useLocation } from 'react-router-dom';

import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getLoginUrl, getLogoutUrl, getSignUpUrl, getUrlPrefix } from 'src/services/http/user';
import { sendMarketingUIEvent } from 'src/utils/analytics';
import { ContentHubType, LandingType } from 'src/constants';

const LINKS: { [key: string]: LinkType } = {
  HOME: { text: 'Home', href: '/' },
  CATALOG: { text: 'Browse all learning', href: '/catalog' },
  BROWSE_ALL: { text: 'Browse all learning', href: '/catalog' },
  LEARNING_PATHS: {
    text: 'Learning paths',
    href: `/hub/${ContentHubType.LEARNING_PATHS}`,
  },
  GROW_YOUR_CAREER: {
    text: 'Grow your career',
    href: `/hub/${ContentHubType.ROLE_BASED}`,
  },
  PREP_FOR_CERT: {
    text: 'Prepare for a certification',
    href: `/hub/${ContentHubType.CERT_PREP}`,
  },
  LIVE_TRAINING: {
    text: 'Live training',
    href: `/landing/${LandingType.LIVE_TRAINING}`,
  },
  CERTIFICATIONS: {
    text: 'Certifications',
    href: `/hub/${ContentHubType.CERTIFICATIONS}`,
  },
  MY_LEARNING: {
    text: 'My learning',
    href: '/my-learning/in-progress',
  },
};

export const withUrlPrefix = (linkType: LinkType): LinkType => ({ ...linkType, href: getUrlPrefix() + linkType.href });

export const AppNavigation: React.FC = () => {
  const { user } = useAtlassianUser();
  const location = useLocation();
  const communityUser = {
    id: user?.account_id || '',
    publicName: user?.name || '',
    avatar: user?.picture || '',
  };

  const loginUrl = useMemo(getLoginUrl, [location]);
  const logoutUrl = useMemo(getLogoutUrl, [location]);
  const signupUrl = useMemo(getSignUpUrl, [location]);

  const showChampions = FeatureGates.checkGate('community-champions-page') ?? false;

  return (
    <CommunityNavigation
      signupHref={signupUrl}
      currentApp={CommunityApp.LEARNING}
      isLoggedIn={!!user}
      user={communityUser}
      loginHref={loginUrl}
      logoutHref={logoutUrl}
      email={user?.email}
      isSticky={false}
      showChampions={showChampions}
      subMenuContentByApp={{
        [CommunityApp.LEARNING]: (
          <>
            <MenuItemWithAnalytics link={withUrlPrefix(LINKS.HOME)} type="subMenu" />
            <NavBarExpandableItem
              headerContent={<span css={onDemandLearningHeaderStyles}>On-demand learning</span>}
              bodyContent={
                <>
                  <MenuItemWithAnalytics link={withUrlPrefix(LINKS.BROWSE_ALL)} type="subMenu" />
                  <MenuItemWithAnalytics link={withUrlPrefix(LINKS.LEARNING_PATHS)} type="subMenu" />
                  <MenuItemWithAnalytics link={withUrlPrefix(LINKS.PREP_FOR_CERT)} type="subMenu" />
                  <MenuItemWithAnalytics link={withUrlPrefix(LINKS.GROW_YOUR_CAREER)} type="subMenu" />
                </>
              }
            />
            <MenuItemWithAnalytics link={withUrlPrefix(LINKS.LIVE_TRAINING)} type="subMenu" />
            <MenuItemWithAnalytics link={withUrlPrefix(LINKS.CERTIFICATIONS)} type="subMenu" />
            {user && <MenuItemWithAnalytics link={withUrlPrefix(LINKS.MY_LEARNING)} type="subMenu" />}
          </>
        ),
      }}
      onAnalyticsSendEvent={(event, payload) => {
        sendMarketingUIEvent(payload, user?.account_id);
      }}
    />
  );
};

const onDemandLearningHeaderStyles = css({
  display: 'flex',
  alignItems: 'center',
});
